<template>
  <v-app
  style="background-color: #FAF7F7">

    <v-main v-if="currentRouteName === 'Login'">
    <Login></Login>
    </v-main>

    <v-app-bar
      v-if="currentRouteName !== 'Login'"
      app
      clipped-left
      color="primary lighten-1"
      class="d-flex justify-center"
    >
      <v-img
        :src="require('./assets/re-cpo-small.png')"
        class="fill-height"
        contain
      ></v-img>


      <v-toolbar-title
        class="white--text ma-12 pr-12"
      >
        RE-CPO
      </v-toolbar-title>
    </v-app-bar>

    <v-main
      v-if="currentRouteName !== 'Login'"
    >

      <v-container class="main">
        <router-view></router-view>
      </v-container>
    </v-main>
    <Footer
      v-if="currentRouteName !== 'Login'"
    ></Footer>

  </v-app>
</template>

<script>
import Footer from "./components/Footer";
import Login from "./views/Login";

export default {
  name: "App",
  components: { Login, Footer },
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  }
};
</script>

<style lang="scss">
:root {
  // Colors are named as the colors in the Material color schemes,
  // and color codes are copied from the colors that are generated by Vuetify
  // from the primary color.
  // https://vuetifyjs.com/en/styles/colors/#material-colors

  --primary-color-lighten-5: #f2ffff;
  --primary-color-lighten-4: #d5ffe6;
  --primary-color-lighten-3: #b9edca;
  --primary-color-lighten-2: #9ed1ae;
  --primary-color-lighten-1: #83b594;
  --primary-color: #699A7A;
  --primary-color-darken-1: #508061;
  --primary-color-darken-2: #376749;
  --primary-color-darken-3: #1f4e33;
  --primary-color-darken-4: #04371d;

  --secondary-color-lighten-5: #fffff1;
  --secondary-color-lighten-4: #FFEBD5;
  --secondary-color-lighten-3: #EECFB9;
  --secondary-color-lighten-2: #d1b49e;
  --secondary-color-lighten-1: #b59984;
  --secondary-color: #9A7F6B;
  --secondary-color-darken-1: #806653;
  --secondary-color-darken-2: #664e3c;
  --secondary-color-darken-3: #4d3726;
  --secondary-color-darken-4: #362211;

  --error-color-lighten-5: #ffc7bf;
  --error-color-lighten-4: #ffaba4;
  --error-color-lighten-3: #ff9089;
  --error-color-lighten-2: #e97570;
  --error-color-lighten-1: #cb5a57;
  --error-color: #ad4040;
  --error-color-darken-1: #90252a;
  --error-color-darken-2: #720115;
  --error-color-darken-3: #570000;
  --error-color-darken-4: #3f0000;

}

.main {
  max-width: 1250px !important;
}

.pointer {
  cursor: pointer;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;600&display=swap');

.v-application .text-h4,
.v-application .text-h5,
.v-application .text-h6, {
  font-family: 'Roboto Mono', sans-serif !important;
}

/* Disable inner arrows in number inputs
https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp
*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

</style>
